import { ReactNode, useContext, useState } from 'react'
import './NavBar.scss'
import { IoClose } from 'react-icons/io5'
import { Link, useNavigate } from 'react-router-dom'
import { RiMenu4Fill } from "react-icons/ri";
import Header from '../header/Header'
import { AiFillHome } from "react-icons/ai";
import { MdImage } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { ImYoutube } from "react-icons/im";
import { getUserLanguage } from '../../utils/Functions';
import { GlobalDataContext } from '../../utils/GlobalValues';

export interface NavBarProps {
    activePage?: string;
}

export const NavBar = (props: NavBarProps) => {
    const [language, setLanguage] = useState(getUserLanguage());
    const globalData = useContext(GlobalDataContext);


    function handleRefresh() {
        window.location.reload();
    }
    // Save the selected language to localStorage whenever it changes
    const handleLanguageChange = (selectedLanguage: string) => {
        localStorage.setItem('selectedLanguage', selectedLanguage);
        setLanguage(selectedLanguage);
        handleRefresh();
    };


    const options: { id: string, icon: ReactNode, text: string, link: string, hide?: boolean }[] = [
        { id: "home", icon: <AiFillHome />, text: globalData.value.navbarHome, link: "/" },
        { id: "portfolio", icon: <MdImage />, text: globalData.value.navbarPortfolio, link: "/portfolio" },
        { id: "videos", icon: <ImYoutube />, text: globalData.value.navbarVideos, link: "/videos", hide: !globalData.value.showVideoPage},
        { id: "about", icon: <FaUser />, text: globalData.value.navbarAboutme, link: "/about" }];

    const languages: { icon: ReactNode, value: string, name: string }[] = [
        { icon: <img src="/img/spain_flag.webp" alt="" />, value: "es", name: "Español" },
        { icon: <img src="/img/uk_flag.png" alt="" />, value: "en", name: "English" }];

    const [navBarHidden, setNavBarHidden] = useState(true);
    return (
        <>
            <div className='navbar | flex-column center-x | pad-m text-white' data-state={navBarHidden ? 'hidden' : 'visible'} onClick={() => { setNavBarHidden(true) }}>
                <div className='navbar__child flex-column center-x | hpad-xxl roundborders shadow bg-img3'>
                    <div className='navbar__child__header'><Header></Header></div>
                    <div className="responsive-row h-full center-y spacebetween-x">
                        <div className="flex-column h-full center-y option">
                            {options.filter(o => !o.hide).map(o =>
                                <Link data-state={o.id == props.activePage ? 'active' : 'inactive'} className="flex-row option | pad-m mpad-xs gap-xxs" to={o.link}>{o.icon}<h2>{o.text}</h2></Link>)}
                        </div>
                        <div className="flex-column h-full center-y option right">
                            {languages.map(l =>
                                <div data-state={l.value == language ? 'active' : 'inactive'} className="flex-row option | pad-m mpad-xs gap-xxs" onClick={() => handleLanguageChange(l.value)}>{l.icon}<h2>{l.name}</h2></div>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="toggle-navbar | roundborders shadow" data-state={navBarHidden ? 'visible' : 'hidden'} onClick={() => { setNavBarHidden(!navBarHidden) }}>
                <RiMenu4Fill className='open' />
                <IoClose className='close' />
            </div>
        </>
    )
}
