import { getUserLocales } from 'get-user-locale';

export function getUserLanguage() {
    const localStorageLanguage = localStorage.getItem('selectedLanguage');
    if (localStorageLanguage) {
        return localStorageLanguage;
    }

    const browserLanguages = getUserLocales();
    const foundLanguage = browserLanguages.find(browserLanguage => {
        return browserLanguage.startsWith("en") || browserLanguage.startsWith("es");
    });

    return foundLanguage ? foundLanguage.slice(0, 2) : 'en';
}

export function textToParagraphs(text: string, useLineBreaks?: boolean) {
    if (useLineBreaks){
        let result: any[] = [];
        const paragraphs = text.split("\n").filter((p: string) => p.length > 1);
        paragraphs.forEach((p, i) => {
            result.push(p)
            if (i < paragraphs.length - 1) {
                result.push(<br></br>)
            }
        })
        return result;
    } else {
        return text.split("\n").filter((p: string) => p.length > 1).map((pa: any) => { return (<p>{pa}</p>) });
    }
}