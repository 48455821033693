import { useContext, useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import WorkDetails from '../Portfolio/Work/WorkDetails';
import './Videos.scss';
import { GlobalDataContext } from '../../utils/GlobalValues';
import Contact from '../../components/contact/Contact';
import { Video } from '../../types/Types';
import { getAllVideos } from '../../api/apiFunctions';
import { textToParagraphs } from '../../utils/Functions';
import { TiArrowRightThick } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import VideoGallery from './VideoGallery/VideoGallery';

const Videos = () => {
    const globalData = useContext(GlobalDataContext);
    const [videos, setVideos] = useState<Video[]>();
    const [featuredVideo, setFeaturedVideo] = useState<Video>();

    useEffect(() => {
        getAllVideos(setVideos);
    }, []);

    useEffect(() => {
        setFeaturedVideo(videos?.find(video => video.featured));
    }, [videos]);

    return <div>
        <div className="welcome | flex-column center-x gap-s | text-white textshadow bg-img8">
            <Header />
            <div className="featured-video-block gap-l">
            <iframe className="shadow roundborders" src={featuredVideo?.url} frameBorder={0} title="YouTube video player" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                <div className="about__intro__bio">
                    <div className="flex-column gap-m">
                        <h2>{featuredVideo?.title}</h2>
                        <div className="flex-column gap-xxs">
                        {textToParagraphs(featuredVideo?.description || "")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="videos-wrapper bg-img9 flex-column center">
            <h2 className='bold uppercase' >{globalData.value.allVideosTitle}</h2>

                <VideoGallery videos={videos || []} />
                {/* {videos?.map(video => {
                    return !video.hidden && <Link to={`/video/${video.sys.id}`}>      
                    <div className="videos__card | bg-white shadow">
                        <img src={video.thumbnail} />
                        <div className="videos__card__text | flex-column pad-m gap-xs">
                            <h4 className="bold uppercase">{video.title}</h4>
                            <div className="flex-column gap-xxs videos__card__text__desc">
                                {textToParagraphs(video.description || "", false)}
                            </div>
                            <div className='btn-wrapper'>
                                <div className="btn button-black">{globalData.value.portfolioSeemore} <TiArrowRightThick style={{ height: "18px" }} /></div>
                            </div>
                        </div>
                    </div>
                    </Link>

                })} */}

        </div>
        <div className='videos__contact'>
            <Contact />
        </div>
    </div>
}

export default Videos;