import { useEffect, useState } from 'react';
import './Video.scss';
import type { Video } from '../../../types/Types';
import { getVideoFromId } from '../../../api/apiFunctions';
import { useParams } from 'react-router-dom';
import GoBack from '../../Portfolio/GoBack/GoBack';
import { textToParagraphs } from '../../../utils/Functions';
import Contact from '../../../components/contact/Contact';

export function Video() {
  const { videoId } = useParams();
  const [video, setVideo] = useState<Video>();

  //_________HOOKS_________
  useEffect(() => {
    if (videoId) {
      getVideoFromId(videoId, setVideo);
    }
  }, [videoId]);

  return <div className="video-page-parent flex-column">
    <div className="video-wrapper pad-s bg-img8 flex-column center">
      <h2 className='bold uppercase' >{video?.title}</h2>
      <GoBack onClick={() => { }} link='/videos'></GoBack>
      <div className='iframe-wrapper | responsive-row gap-xl mgap-l'>
        <div className="work-details__text | flex-column gap-xs">
          <h4 >{video?.title}</h4>
          {video && textToParagraphs(video.description)}
        </div>
        <iframe className="shadow roundborders" src={video?.url} frameBorder={0} title="YouTube video player" allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
      </div>

    </div>
    <Contact />
  </div>
}
