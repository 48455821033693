import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import './VideoGallery.scss';
import { TiArrowRightThick } from "react-icons/ti";
import { Link } from 'react-router-dom';
import { Video } from '../../../types/Types';
import { getAllVideos} from '../../../api/apiFunctions';
import { GlobalDataContext } from '../../../utils/GlobalValues';

interface VideoGalleryProps {
    videos: Video[];
    rowCount?: {
        bigScreen: number;
        mediumScreen: number;
        smallScreen: number;
    }
}

const VideoGallery = (props: VideoGalleryProps) => {
    //_________VARIABLES_________
    const rowCountArray = props.rowCount || {bigScreen: 3, mediumScreen:2, smallScreen:1};
    const imageWidth = 512;
    const [rowCount, setRowCount] = useState<number>(rowCountArray.bigScreen);
    const [galleryWorks, setGalleryWorks] = useState<Video[]>([]);
    const [galleryWorksRows, setgalleryWorksRows] = useState<Video[][]>([]);
    const mediumScreen = useMediaQuery('(min-width: 799.99px)');
    const bigScreen = useMediaQuery('(min-width: 1500px)');
    const globalData = useContext(GlobalDataContext);

    //____________FUNCTIONS__________


    function calculateRowCount(): number {
        if (mediumScreen) {
            if (bigScreen) {
                return rowCountArray.bigScreen;
            }
            return rowCountArray.mediumScreen;
        }
        return rowCountArray.smallScreen;
    }

    function getImageRowHeight(row: Video[]): number {
        let rowHeight = 0;

        row.forEach((work: Video) => {
            const img = work.thumbnail;
            rowHeight += (imageWidth / 1280) * 720;
        })

        return rowHeight;
    }

    function getImageRowsFromImages(images: Video[], numberOfRows: number) {
        let imageRows: Video[][] = [];
        for (let index = 0; index < numberOfRows; index++) {
            imageRows.push([]);
        }

        images.forEach((work: Video) => {
            imageRows.sort(function (a, b) {
                if (getImageRowHeight(a) > getImageRowHeight(b)) {
                    return 1;
                }
                if (getImageRowHeight(a) < getImageRowHeight(b)) {
                    return -1;
                }
                return 0;
            })[0].push(work);
        })

        return imageRows;
    }

    //_________HOOKS_________
    useEffect(() => {
        getAllVideos(setGalleryWorks);
    }, []);

    useEffect(() => {
        setRowCount(calculateRowCount);
    }, [mediumScreen, bigScreen]);

    useEffect(() => {
        setgalleryWorksRows(getImageRowsFromImages(galleryWorks, galleryWorks.length < rowCount ? galleryWorks.length : rowCount));
    }, [galleryWorks, rowCount]);

    //_________COMPONENT_________
    return galleryWorks.length > 0 ? (
        <div className="gallery">
            {galleryWorksRows &&
                <div className="gallery__wrapper | gap-s " style={{ gridTemplateColumns: `repeat(${rowCount}, 1fr)` }}>
                    {galleryWorksRows && galleryWorksRows.map((imageRow: Video[], i: number) => (
                        <div key={"row" + i} className='flex-column | gap-s'>
                            {imageRow.map((video: Video, j: number) => (
                                <Link to={`/video/${video.sys.id}`}>
                                    <div className='img-parent'>
                                        <img className='shadow roundborder' key={"img" + j} src={video.thumbnail + `?w=${imageWidth}&fm=webp&q=100`}></img>
                                        
                                        <div className="img-overlay | flex-column center gap-xs | roundborder text-white">
                                            <h5>{video.title}</h5>
                                            <div className="button-white pad-xxs2">{globalData.value.portfolioSeemore} <TiArrowRightThick style={{ height: "18px" }} /></div>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    ))}
                </div>}
        </div>
    ) : <div className="white-bg | pad-xxl" >No hay contenido que mostrar (por el momento).</div>
}

export default VideoGallery